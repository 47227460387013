var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c(
            "b-card-body",
            [
              _c(
                "b-tabs",
                {
                  staticClass: "tabs-categories",
                  attrs: {
                    vertical: "",
                    pills: "",
                    "nav-wrapper-class": "nav-vertical",
                  },
                },
                _vm._l(
                  this.optionsBannerCategories,
                  function (category, index) {
                    return _c(
                      "b-tab",
                      {
                        key: category.code,
                        attrs: {
                          active:
                            _vm.item.category === category.code ? true : false,
                          title: category.title,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onTabChange(category, index)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _vm._v(" " + _vm._s(category.title) + " "),
                                  _c(
                                    "b-badge",
                                    {
                                      staticClass: "float-right ml-2",
                                      attrs: {
                                        pill: "",
                                        variant: "light-primary",
                                      },
                                    },
                                    [_vm._v(_vm._s(category.count))]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c("h3", { staticClass: "mb-2" }, [
                          _vm._v(_vm._s(_vm.titlePage)),
                        ]),
                        _vm.dataItems.length
                          ? _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.item.category !== 9 &&
                                      _vm.item.category !== 10
                                      ? "Mova o banner para organizar. Os banners serão exibidos por ordem crescente."
                                      : ""
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _vm.loadingItems
                          ? _c(
                              "p",
                              { staticClass: "text-left py-3 mb-0" },
                              [
                                _c("b-spinner", {
                                  staticClass: "mr-1",
                                  attrs: { label: "Loading...", small: "" },
                                }),
                                _vm._v(" Verificando banners cadastrados... "),
                              ],
                              1
                            )
                          : _c("div", [
                              _c(
                                "div",
                                [
                                  _c(
                                    "draggable",
                                    {
                                      staticClass:
                                        "list-group list-group-flush my-2",
                                      class: {
                                        "cursor-move": !_vm.showDraggable(
                                          _vm.item.category
                                        ),
                                      },
                                      attrs: {
                                        disabled: _vm.showDraggable(
                                          _vm.item.category
                                        ),
                                        tag: "ul",
                                      },
                                      on: { change: _vm.onChangeSort },
                                      model: {
                                        value: _vm.dataItems,
                                        callback: function ($$v) {
                                          _vm.dataItems = $$v
                                        },
                                        expression: "dataItems",
                                      },
                                    },
                                    _vm._l(
                                      _vm.dataItems,
                                      function (item, index) {
                                        return _c(
                                          "b-list-group-item",
                                          { key: index, attrs: { tag: "li" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                              },
                                              [
                                                !_vm.showDraggable(
                                                  item.category_id
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "sort-number mr-0",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "bi bi-arrows-move",
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                !_vm.showDraggable(
                                                  item.category_id
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "sort-number",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(index + 1) +
                                                            "º "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c("b-avatar", {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    rounded: "sm",
                                                    size: "42",
                                                    src: item.image,
                                                    title:
                                                      item.title_alternative,
                                                    href: item.link,
                                                    target: "_blank",
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  { staticClass: "ml-0" },
                                                  [
                                                    _c(
                                                      "b-card-text",
                                                      {
                                                        staticClass:
                                                          "font-weight-bolder mb-0",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.title) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-card-text",
                                                      { staticClass: "mb-0" },
                                                      [
                                                        _c(
                                                          "small",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "0.9rem",
                                                            },
                                                          },
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                "Data de Exibição:"
                                                              ),
                                                            ]),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "datePT"
                                                                  )(
                                                                    item.start,
                                                                    true
                                                                  )
                                                                ) +
                                                                " até " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "datePT"
                                                                  )(
                                                                    item.end,
                                                                    true
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("small", [
                                                          _c("strong", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.label_image
                                                              )
                                                            ),
                                                          ]),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-badge",
                                                      {
                                                        staticStyle: {
                                                          "margin-right": "5px",
                                                        },
                                                        attrs: {
                                                          variant:
                                                            _vm.isItemActive(
                                                              item.status
                                                            )
                                                              ? "success"
                                                              : "danger",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.isItemActive(
                                                              item.status
                                                            )
                                                              ? "Ativo"
                                                              : "Inativo"
                                                          ) + " "
                                                        ),
                                                      ]
                                                    ),
                                                    item.end &&
                                                    _vm.isItemExpired(item.end)
                                                      ? _c(
                                                          "b-badge",
                                                          {
                                                            attrs: {
                                                              variant:
                                                                _vm.isItemExpired(
                                                                  item.end
                                                                )
                                                                  ? "danger"
                                                                  : "light-dark",
                                                            },
                                                          },
                                                          [_vm._v("Expirado ")]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "ml-auto",
                                                    staticStyle: {
                                                      "min-width": "100px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-row pt-1",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-4 text-center",
                                                            staticStyle: {
                                                              cursor:
                                                                "pointer !important",
                                                            },
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              staticClass:
                                                                "text-danger",
                                                              attrs: {
                                                                icon: "Trash2Icon",
                                                                size: "18",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.confirmDelete(
                                                                      item.uuid
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-4 text-center",
                                                            staticStyle: {
                                                              cursor:
                                                                "pointer !important",
                                                            },
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              staticClass:
                                                                "mx-1 m-auto",
                                                              attrs: {
                                                                icon: "EditIcon",
                                                                size: "18",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.modalEdit(
                                                                      item.uuid
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-4 text-center",
                                                            staticStyle: {
                                                              cursor:
                                                                "pointer !important",
                                                            },
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              attrs: {
                                                                icon: "EyeIcon",
                                                                size: "18",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.modalDetails(
                                                                      item.uuid
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                  _vm.updatingBanners
                                    ? _c(
                                        "p",
                                        { staticClass: "mb-0te text-success" },
                                        [
                                          _c("b-spinner", {
                                            staticClass: "mr-1",
                                            attrs: {
                                              label: "Loading...",
                                              small: "",
                                            },
                                          }),
                                          _vm._v(" Atualizando... "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                        !_vm.dataItems.length && !_vm.loadingItems
                          ? _c("p", [_vm._v(" Nenhum banner encontrado ")])
                          : _vm._e(),
                      ]
                    )
                  }
                ),
                1
              ),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "modal-create-banner",
              attrs: {
                "modal-class": "modal-create",
                title: _vm.titleModal,
                centered: "",
                "hide-footer": "",
              },
            },
            [
              _c(
                "form",
                {
                  attrs: { enctype: "multipart/form-data" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitCreate.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Título interno "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("b-form-input", {
                            class: { "is-invalid": _vm.$v.item.title.$error },
                            model: {
                              value: _vm.item.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "title", $$v)
                              },
                              expression: "item.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Título na imagem "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("b-form-input", {
                            class: {
                              "is-invalid":
                                _vm.$v.item.title_alternative.$error,
                            },
                            model: {
                              value: _vm.item.title_alternative,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "title_alternative", $$v)
                              },
                              expression: "item.title_alternative",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Largura "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "v-select",
                            {
                              ref: "selectLargura",
                              class: { "is-invalid": _vm.$v.item.width.$error },
                              attrs: {
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                options: [
                                  { code: "normal", title: "Normal" },
                                  { code: "full", title: "Full" },
                                ],
                                searchable: false,
                              },
                              model: {
                                value: _vm.item.width,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item, "width", $$v)
                                },
                                expression: "item.width",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  on: {
                                    click: function ($event) {
                                      _vm.$refs.selectLargura.open = false
                                    },
                                  },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhuma opção encontrada ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-5" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Abertura "),
                          ]),
                          _c(
                            "v-select",
                            {
                              ref: "selectAbertura",
                              class: {
                                "is-invalid": _vm.$v.item.new_window.$error,
                              },
                              attrs: {
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                options: _vm.optionsWindow,
                                searchable: false,
                              },
                              model: {
                                value: _vm.item.new_window,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item, "new_window", $$v)
                                },
                                expression: "item.new_window",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  on: {
                                    click: function ($event) {
                                      _vm.$refs.selectAbertura.open = false
                                    },
                                  },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhuma opção encontrada ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Cor de fundo"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.background,
                              expression: "item.background",
                            },
                          ],
                          staticClass: "form-control",
                          style: "color: " + _vm.item.background,
                          attrs: { type: "text" },
                          domProps: { value: _vm.item.background },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "background",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Link")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.link,
                              expression: "item.link",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder:
                              "Link completo ou /concursos/concurso-inss-técnico",
                          },
                          domProps: { value: _vm.item.link },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.item, "link", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Início da exibição "),
                          ]),
                          _c("flat-pickr", {
                            staticClass: "form-control",
                            class: { "is-invalid": _vm.$v.item.start.$error },
                            attrs: {
                              config: {
                                dateFormat: "d/m/Y H:i",
                                altFormat: "Y-m-d",
                                locale: "pt",
                                enableTime: true,
                                defaultDate: "today",
                                defaultHour: 0,
                                minuteIncrement: 1,
                                time_24hr: true,
                                wrap: true,
                              },
                            },
                            model: {
                              value: _vm.item.start,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "start", $$v)
                              },
                              expression: "item.start",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Fim da exibição "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("flat-pickr", {
                            staticClass: "form-control",
                            class: { "is-invalid": _vm.$v.item.end.$error },
                            attrs: {
                              config: {
                                dateFormat: "d/m/Y H:i",
                                locale: "pt",
                                enableTime: true,
                                defaultHour: 23,
                                defaultMinute: 59,
                                minuteIncrement: 1,
                                time_24hr: true,
                                wrap: true,
                              },
                            },
                            model: {
                              value: _vm.item.end,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "end", $$v)
                              },
                              expression: "item.end",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Status "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("v-select", {
                            class: { "is-invalid": _vm.$v.item.status.$error },
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              options: _vm.status,
                              searchable: false,
                            },
                            model: {
                              value: _vm.item.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "status", $$v)
                              },
                              expression: "item.status",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm.item.category === 9 || _vm.item.category === 10
                    ? _c(
                        "div",
                        { staticClass: "form-row select-products-selectes" },
                        [
                          _vm.item.category === 9
                            ? _c("b-col", { attrs: { md: "12" } }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _c("i", {
                                        staticClass:
                                          "text-danger bi bi-record-circle",
                                      }),
                                      _vm._v(
                                        " Produto(s) " +
                                          _vm._s(_vm.item.products.length) +
                                          " "
                                      ),
                                    ]),
                                    _c(
                                      "v-select",
                                      {
                                        ref: "selectProduct",
                                        class: {
                                          "is-invalid":
                                            _vm.$v.item.products.$error,
                                        },
                                        attrs: {
                                          multiple: "",
                                          label: "title",
                                          "item-text": "title",
                                          "item-value": "code",
                                          placeholder: "Digite o título",
                                          options: _vm.optionsProducts,
                                        },
                                        on: { search: _vm.fetchProducts },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selected-option-container",
                                              fn: function () {
                                                return [_c("div")]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          1415734622
                                        ),
                                        model: {
                                          value: _vm.item.products,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.item, "products", $$v)
                                          },
                                          expression: "item.products",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "no-options" },
                                            on: {
                                              click: function ($event) {
                                                _vm.$refs.selectProduct.open = false
                                              },
                                            },
                                            slot: "no-options",
                                          },
                                          [
                                            _vm._v(
                                              " Nenhum registro encontrado "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm.item.products.length > 0
                                      ? _c(
                                          "div",
                                          { staticClass: "selected-items" },
                                          _vm._l(
                                            _vm.item.products,
                                            function (product, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass: "chip",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(product.title) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass: "chip-close",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeItem(
                                                            _vm.item.products,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" x ")]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.item.category === 10
                            ? _c("b-col", { attrs: { md: "12" } }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _c("i", {
                                        staticClass:
                                          "text-danger bi bi-record-circle",
                                      }),
                                      _vm._v(" Concurso(s) "),
                                    ]),
                                    _c(
                                      "v-select",
                                      {
                                        class: {
                                          "is-invalid":
                                            _vm.$v.item.contests.$error,
                                        },
                                        attrs: {
                                          multiple: "",
                                          label: "title",
                                          "item-text": "title",
                                          "item-value": "code",
                                          placeholder: "Digite o título",
                                          options: _vm.optionsContests,
                                        },
                                        on: { search: _vm.fetchContests },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selected-option-container",
                                              fn: function () {
                                                return [_c("div")]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          1415734622
                                        ),
                                        model: {
                                          value: _vm.item.contests,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.item, "contests", $$v)
                                          },
                                          expression: "item.contests",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "no-options" },
                                            slot: "no-options",
                                          },
                                          [
                                            _vm._v(
                                              " Nenhum registro encontrado "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm.item.contests.length > 0
                                      ? _c(
                                          "div",
                                          { staticClass: "selected-items" },
                                          _vm._l(
                                            _vm.item.contests,
                                            function (contest, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass: "chip",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(contest.title) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass: "chip-close",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.removeItem(
                                                            _vm.item.contests,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" x ")]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.item.category === 11
                    ? _c(
                        "div",
                        { staticClass: "form-row" },
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Qual sessão? "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    class: {
                                      "is-invalid": _vm.$v.item.session.$error,
                                    },
                                    attrs: {
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      options: [
                                        { code: 1, title: "Cursos online" },
                                        { code: 2, title: "Lives" },
                                        { code: 3, title: "Presenciais" },
                                      ],
                                      searchable: false,
                                    },
                                    model: {
                                      value: _vm.item.session,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "session", $$v)
                                      },
                                      expression: "item.session",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.item.category === 13
                    ? _c(
                        "div",
                        { staticClass: "form-row" },
                        [
                          _c("b-col", { attrs: { md: "4" } }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Categoria "),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    attrs: {
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      placeholder: "Área",
                                      options: _vm.optionsAreas,
                                    },
                                    on: { search: _vm.fetchAreas },
                                    model: {
                                      value: _vm.item.area,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "area", $$v)
                                      },
                                      expression: "item.area",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("hr"),
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c("b-col", { attrs: { md: "4" } }, [
                        _c("label", [
                          _vm._v("Mobile "),
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                        ]),
                        _c("input", {
                          staticClass: "hidden",
                          attrs: {
                            id: "image_mobile",
                            type: "file",
                            accept: "image/webp",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.previewImage($event, "mobile")
                            },
                          },
                        }),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "d-block text-center relative",
                              class: _vm.item.uuid
                                ? ""
                                : _vm.$v.preview_mobile.$error
                                ? "banner-area is-invalid"
                                : "",
                              staticStyle: {
                                border: "1px solid rgb(222 222 222)",
                                "border-radius": "4px",
                                "border-style": "dashed",
                                cursor: "pointer",
                              },
                              attrs: { for: "image_mobile" },
                            },
                            [
                              !_vm.preview_mobile && !_vm.item.image_mobile
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "pt-5 py-4",
                                      staticStyle: {
                                        color: "#6e6e6e",
                                        "font-size": "0.8rem",
                                      },
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: {
                                          size: "19",
                                          icon: "ImageIcon",
                                        },
                                      }),
                                      _vm._v(" Imagem mobile "),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.preview_mobile || _vm.item.image_mobile
                                ? [
                                    _c("b-img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "200px",
                                        "border-radius": "3px",
                                      },
                                      attrs: {
                                        for: "image",
                                        src:
                                          _vm.preview_mobile ||
                                          _vm.item.image_mobile,
                                        fluid: "",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ]),
                      _c("b-col", { attrs: { md: "8" } }, [
                        _c("label", [
                          _vm._v("Desktop "),
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                        ]),
                        _c("input", {
                          staticClass: "hidden",
                          attrs: {
                            id: "image_desktop",
                            type: "file",
                            accept: "image/webp",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.previewImage($event, "desktop")
                            },
                          },
                        }),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "d-block text-center",
                              class: _vm.item.uuid
                                ? ""
                                : _vm.$v.preview_desktop.$error
                                ? "banner-area is-invalid"
                                : "",
                              staticStyle: {
                                border: "1px solid rgb(222 222 222)",
                                "border-radius": "4px",
                                "border-style": "dashed",
                                cursor: "pointer",
                                "font-size": "1.1rem",
                              },
                              attrs: { for: "image_desktop" },
                            },
                            [
                              !_vm.preview_desktop && !_vm.item.image_desktop
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "pt-5 py-4",
                                      staticStyle: {
                                        color: "#6e6e6e",
                                        "font-size": "0.8rem",
                                      },
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: {
                                          size: "19",
                                          icon: "ImageIcon",
                                        },
                                      }),
                                      _vm._v(" Imagem desktop "),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.preview_desktop || _vm.item.image_desktop
                                ? [
                                    _c("b-img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "200px",
                                        "border-radius": "3px",
                                      },
                                      attrs: {
                                        for: "image",
                                        src:
                                          _vm.preview_desktop ||
                                          _vm.item.image_desktop,
                                        fluid: "",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        [
                          _c(
                            "b-alert",
                            {
                              attrs: {
                                variant: "dark",
                                show: "",
                                "d-block": "",
                              },
                            },
                            [
                              _c("h4", { staticClass: "alert-heading" }, [
                                _vm._v("Atenção as dimensões"),
                              ]),
                              _c("div", { staticClass: "alert-body" }, [
                                _c("p", { staticClass: "mb-1" }, [
                                  _vm._v(
                                    " É recomendável que as imagens tenham as seguintes dimensões: "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " - Desktop: 1166px de largura e 300px de altura."
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " - Mobile: 400px de largura e 440px de altura."
                                  ),
                                  _c("br"),
                                ]),
                                _c("strong", [
                                  _vm._v("Como será aplicado a cor de fundo?"),
                                ]),
                                _c("p", { staticClass: "mb-1" }, [
                                  _vm._v(
                                    " Caso você o banner seja cadastrado com a Largura normal, a cor de fundo será aplicada além da largura do banner. "
                                  ),
                                ]),
                                _c("strong", [
                                  _vm._v(
                                    "O que acontecerá com o banner após o período de exibição?"
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    " Após o período de exibição, o banner será desativado automaticamente e, consequentemente, deixará de ser exibido no site. "
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-row justify-content-end modal-footer-custom mt-0",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-12" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light float-left",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeModalCreate()
                                },
                              },
                            },
                            [_vm._v(" Fechar ")]
                          ),
                          _c("ButtonsActionsFooter", {
                            attrs: {
                              variant: "success",
                              submited: _vm.submited,
                              text: _vm.textSubmit,
                              subtext: "Aguarde...",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm.banner
            ? _c(
                "b-modal",
                {
                  attrs: {
                    id: "showBanner",
                    "modal-class": "modal-show",
                    size: "lg",
                    title: _vm.titleModal,
                    centered: "",
                    "hide-footer": "",
                    "no-close-on-backdrop": "",
                  },
                },
                [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "col-md-12 col-12" }, [
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("strong", [_vm._v("Título: ")]),
                            _vm._v(" " + _vm._s(_vm.banner.title) + " "),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("strong", [_vm._v("Título interno: ")]),
                            _vm._v(
                              " " + _vm._s(_vm.banner.title_alternative) + " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("strong", [_vm._v("Largura: ")]),
                            _c("span", { staticClass: "text-capitalize" }, [
                              _vm._v(_vm._s(_vm.banner.width.title)),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("strong", [_vm._v("Exibir em: ")]),
                            _vm._v(
                              " " + _vm._s(_vm.banner.category.name) + " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("strong", [_vm._v("Abertura: ")]),
                            _vm._v(
                              " " + _vm._s(_vm.banner.new_window.title) + " "
                            ),
                          ]),
                        ]),
                        _vm.banner.session
                          ? _c("div", { staticClass: "col-md-3" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("strong", [_vm._v("Sessão: ")]),
                                _vm._v(
                                  " " + _vm._s(_vm.banner.session.title) + " "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.banner.link
                          ? _c("div", { staticClass: "col-md-3" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("strong", [_vm._v("Link: ")]),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.banner.link,
                                      target: "_blank",
                                      rel: "noopener noreferrer",
                                    },
                                  },
                                  [
                                    _vm._v(" Abrir "),
                                    _c("i", {
                                      staticClass: "bi bi-box-arrow-up-right",
                                    }),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.banner.background
                          ? _c("div", { staticClass: "col-md-3" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("strong", [_vm._v("Cor de fundo: ")]),
                                _vm._v(
                                  " " + _vm._s(_vm.banner.background) + " "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "col-md-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("strong", [_vm._v("Exibir de: ")]),
                            _vm._v(" " + _vm._s(_vm.banner.start) + " "),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-md-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("strong", [_vm._v("Até: ")]),
                            _vm._v(" " + _vm._s(_vm.banner.end) + " "),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("hr"),
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c("b-img", {
                          staticStyle: {
                            width: "100%",
                            height: "200px",
                            "border-radius": "3px",
                          },
                          attrs: {
                            for: "image",
                            src: _vm.banner.image_mobile,
                            fluid: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-8" },
                      [
                        _c("b-img", {
                          staticStyle: {
                            width: "100%",
                            height: "200px",
                            "border-radius": "3px",
                          },
                          attrs: {
                            for: "image",
                            src: _vm.banner.image_desktop,
                            fluid: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }