<template>
  <div>
    <b-card no-body class="mb-0">
      <b-card-body>
        <b-tabs
          vertical
          pills
          nav-wrapper-class="nav-vertical"
          class="tabs-categories"
        >
          <b-tab
            v-for="(category, index) in this.optionsBannerCategories"
            :active="item.category === category.code ? true : false"
            :title="category.title"
            :key="category.code"
            @click="onTabChange(category, index)"
          >
            <template #title>
              {{ category.title }}
              <b-badge class="float-right ml-2" pill variant="light-primary">{{
                category.count
              }}</b-badge>
            </template>

            <h3 class="mb-2">{{ titlePage }}</h3>

            <p v-if="dataItems.length" class="mb-0">
              {{
                item.category !== 9 && item.category !== 10
                  ? "Mova o banner para organizar. Os banners serão exibidos por ordem crescente."
                  : ""
              }}
            </p>

            <p class="text-left py-3 mb-0" v-if="loadingItems">
              <b-spinner label="Loading..." small class="mr-1" /> Verificando
              banners cadastrados...
            </p>

            <div v-else>
              <div>
                <draggable
                  v-model="dataItems"
                  :disabled="showDraggable(item.category)"
                  :class="{ 'cursor-move': !showDraggable(item.category) }"
                  class="list-group list-group-flush my-2"
                  tag="ul"
                  @change="onChangeSort"
                >
                  <b-list-group-item
                    v-for="(item, index) in dataItems"
                    :key="index"
                    tag="li"
                  >
                    <div class="d-flex align-items-center">
                      <div
                        class="sort-number mr-0"
                        v-if="!showDraggable(item.category_id)"
                      >
                        <i class="bi bi-arrows-move"></i>
                      </div>
                      <div
                        class="sort-number"
                        v-if="!showDraggable(item.category_id)"
                      >
                        {{ index + 1 }}º
                      </div>
                      <b-avatar
                        rounded="sm"
                        size="42"
                        :src="item.image"
                        :title="item.title_alternative"
                        :href="item.link"
                        target="_blank"
                        class="mr-1"
                      />
                      <div class="ml-0">
                        <b-card-text class="font-weight-bolder mb-0">
                          {{ item.title }}
                        </b-card-text>
                        <b-card-text class="mb-0">
                          <small style="font-size: 0.9rem">
                            <strong>Data de Exibição:</strong>
                            {{ item.start | datePT(true) }} até
                            {{ item.end | datePT(true) }}
                          </small>
                          <small
                            ><strong>{{ item.label_image }}</strong></small
                          >
                        </b-card-text>
                        <b-badge
                          style="margin-right: 5px"
                          :variant="
                            isItemActive(item.status) ? 'success' : 'danger'
                          "
                          >{{ isItemActive(item.status) ? "Ativo" : "Inativo" }}
                        </b-badge>
                        <b-badge
                          v-if="item.end && isItemExpired(item.end)"
                          :variant="
                            isItemExpired(item.end) ? 'danger' : 'light-dark'
                          "
                          >Expirado
                        </b-badge>
                      </div>

                      <div class="ml-auto" style="min-width: 100px">
                        <div class="form-row pt-1">
                          <div
                            class="col-4 text-center"
                            style="cursor: pointer !important"
                          >
                            <feather-icon
                              icon="Trash2Icon"
                              size="18"
                              class="text-danger"
                              @click="confirmDelete(item.uuid)"
                            />
                          </div>
                          <div
                            class="col-4 text-center"
                            style="cursor: pointer !important"
                          >
                            <feather-icon
                              icon="EditIcon"
                              class="mx-1 m-auto"
                              size="18"
                              @click="modalEdit(item.uuid)"
                            />
                          </div>
                          <div
                            class="col-4 text-center"
                            style="cursor: pointer !important"
                          >
                            <feather-icon
                              icon="EyeIcon"
                              size="18"
                              @click="modalDetails(item.uuid)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-list-group-item>
                </draggable>

                <p v-if="updatingBanners" class="mb-0te text-success">
                  <b-spinner label="Loading..." small class="mr-1" />
                  Atualizando...
                </p>
              </div>
            </div>
            <p v-if="!dataItems.length && !loadingItems">
              Nenhum banner encontrado
            </p>
          </b-tab>
        </b-tabs>
      </b-card-body>

      <b-modal
        ref="modal-create-banner"
        modal-class="modal-create"
        :title="titleModal"
        centered
        hide-footer
      >
        <form @submit.prevent="submitCreate" enctype="multipart/form-data">
          <div class="form-row">
            <div class="col-md-6">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Título interno
              </label>
              <div class="form-group">
                <b-form-input
                  v-model="item.title"
                  :class="{ 'is-invalid': $v.item.title.$error }"
                />
              </div>
            </div>
            <div class="col-md-6">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Título na imagem
              </label>
              <div class="form-group">
                <b-form-input
                  v-model="item.title_alternative"
                  :class="{
                    'is-invalid': $v.item.title_alternative.$error,
                  }"
                />
              </div>
            </div>

            <div class="col-md-4">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Largura
              </label>
              <div class="form-group">
                <v-select
                  ref="selectLargura"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.width"
                  :options="[
                    { code: 'normal', title: 'Normal' },
                    { code: 'full', title: 'Full' },
                  ]"
                  :searchable="false"
                  :class="{ 'is-invalid': $v.item.width.$error }"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectLargura.open = false"
                  >
                    Nenhuma opção encontrada
                  </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-5">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Abertura
                </label>
                <v-select
                  ref="selectAbertura"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.new_window"
                  :options="optionsWindow"
                  :class="{ 'is-invalid': $v.item.new_window.$error }"
                  :searchable="false"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectAbertura.open = false"
                  >
                    Nenhuma opção encontrada
                  </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label for="">Cor de fundo</label>
                <input
                  type="text"
                  :style="`color: ${item.background}`"
                  v-model="item.background"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label for="">Link</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Link completo ou /concursos/concurso-inss-técnico"
                  v-model="item.link"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Início da exibição
                </label>
                <flat-pickr
                  v-model="item.start"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y H:i',
                    altFormat: 'Y-m-d',
                    locale: 'pt',
                    enableTime: true,
                    defaultDate: 'today',
                    defaultHour: 0,
                    minuteIncrement: 1,
                    time_24hr: true,
                    wrap: true,
                  }"
                  :class="{ 'is-invalid': $v.item.start.$error }"
                />
              </div>
            </div>

            <div class="col-md-4">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Fim da exibição
              </label>
              <div class="form-group">
                <flat-pickr
                  v-model="item.end"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y H:i',
                    locale: 'pt',
                    enableTime: true,
                    defaultHour: 23,
                    defaultMinute: 59,
                    minuteIncrement: 1,
                    time_24hr: true,
                    wrap: true,
                  }"
                  :class="{ 'is-invalid': $v.item.end.$error }"
                />
              </div>
            </div>

            <div class="col-md-4">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Status
              </label>
              <div class="form-group">
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.status"
                  :options="status"
                  :class="{ 'is-invalid': $v.item.status.$error }"
                  :searchable="false"
                ></v-select>
              </div>
            </div>
          </div>

          <div
            class="form-row select-products-selectes"
            v-if="item.category === 9 || item.category === 10"
          >
            <b-col md="12" v-if="item.category === 9">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Produto(s) {{ item.products.length }}
                </label>
                <v-select
                  multiple
                  ref="selectProduct"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.products"
                  placeholder="Digite o título"
                  :options="optionsProducts"
                  @search="fetchProducts"
                  :class="{ 'is-invalid': $v.item.products.$error }"
                >
                  <template #selected-option-container>
                    <div></div>
                  </template>
                  <span
                    slot="no-options"
                    @click="$refs.selectProduct.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
                <div class="selected-items" v-if="item.products.length > 0">
                  <div
                    class="chip"
                    v-for="(product, index) in item.products"
                    :key="index"
                  >
                    {{ product.title }}
                    <button
                      class="chip-close"
                      @click="removeItem(item.products, index)"
                    >
                      x
                    </button>
                  </div>
                </div>
              </div>
            </b-col>

            <b-col md="12" v-if="item.category === 10">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Concurso(s)
                </label>
                <v-select
                  multiple
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.contests"
                  placeholder="Digite o título"
                  :options="optionsContests"
                  @search="fetchContests"
                  :class="{
                    'is-invalid': $v.item.contests.$error,
                  }"
                >
                  <template #selected-option-container>
                    <div></div>
                  </template>
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
                <div class="selected-items" v-if="item.contests.length > 0">
                  <div
                    class="chip"
                    v-for="(contest, index) in item.contests"
                    :key="index"
                  >
                    {{ contest.title }}
                    <button
                      class="chip-close"
                      @click.prevent="removeItem(item.contests, index)"
                    >
                      x
                    </button>
                  </div>
                </div>
              </div>
            </b-col>
          </div>

          <div class="form-row" v-if="item.category === 11">
            <b-col md="12">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Qual sessão?
                </label>
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.session"
                  :options="[
                    { code: 1, title: 'Cursos online' },
                    { code: 2, title: 'Lives' },
                    { code: 3, title: 'Presenciais' },
                  ]"
                  :class="{ 'is-invalid': $v.item.session.$error }"
                  :searchable="false"
                >
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </b-col>
          </div>

          <div class="form-row" v-if="item.category === 13">
            <b-col md="4">
              <div class="form-group">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Categoria
                </label>
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.area"
                  placeholder="Área"
                  :options="optionsAreas"
                  @search="fetchAreas"
                >
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </b-col>
          </div>
          <hr />
          <div class="form-row">
            <b-col md="4">
              <label
                >Mobile <i class="text-danger bi bi-record-circle"></i
              ></label>
              <input
                id="image_mobile"
                type="file"
                accept="image/webp"
                class="hidden"
                @change="previewImage($event, 'mobile')"
              />
              <div class="form-group">
                <label
                  for="image_mobile"
                  class="d-block text-center relative"
                  :class="
                    item.uuid
                      ? ''
                      : $v.preview_mobile.$error
                      ? 'banner-area is-invalid'
                      : ''
                  "
                  style="
                    border: 1px solid rgb(222 222 222);
                    border-radius: 4px;
                    border-style: dashed;
                    cursor: pointer;
                  "
                >
                  <div
                    v-if="!preview_mobile && !item.image_mobile"
                    class="pt-5 py-4"
                    style="color: #6e6e6e; font-size: 0.8rem"
                  >
                    <feather-icon size="19" icon="ImageIcon" />
                    Imagem mobile
                  </div>
                  <template v-if="preview_mobile || item.image_mobile">
                    <b-img
                      for="image"
                      :src="preview_mobile || item.image_mobile"
                      style="width: 100%; height: 200px; border-radius: 3px"
                      fluid
                    />
                  </template>
                </label>
              </div>
            </b-col>
            <b-col md="8">
              <label
                >Desktop <i class="text-danger bi bi-record-circle"></i
              ></label>
              <input
                id="image_desktop"
                type="file"
                accept="image/webp"
                class="hidden"
                @change="previewImage($event, 'desktop')"
              />
              <div class="form-group">
                <label
                  for="image_desktop"
                  :class="
                    item.uuid
                      ? ''
                      : $v.preview_desktop.$error
                      ? 'banner-area is-invalid'
                      : ''
                  "
                  class="d-block text-center"
                  style="
                    border: 1px solid rgb(222 222 222);
                    border-radius: 4px;
                    border-style: dashed;
                    cursor: pointer;
                    font-size: 1.1rem;
                  "
                >
                  <div
                    v-if="!preview_desktop && !item.image_desktop"
                    class="pt-5 py-4"
                    style="color: #6e6e6e; font-size: 0.8rem"
                  >
                    <feather-icon size="19" icon="ImageIcon" />
                    Imagem desktop
                  </div>
                  <template v-if="preview_desktop || item.image_desktop">
                    <b-img
                      for="image"
                      :src="preview_desktop || item.image_desktop"
                      style="width: 100%; height: 200px; border-radius: 3px"
                      fluid
                    />
                  </template>
                </label>
              </div>
            </b-col>
          </div>
          <div class="form-row">
            <b-col md="12">
              <b-alert variant="dark" show d-block>
                <h4 class="alert-heading">Atenção as dimensões</h4>
                <div class="alert-body">
                  <p class="mb-1">
                    É recomendável que as imagens tenham as seguintes dimensões:
                    <br />
                    - Desktop: 1166px de largura e 300px de altura.<br />
                    - Mobile: 400px de largura e 440px de altura.<br />
                  </p>
                  <strong>Como será aplicado a cor de fundo?</strong>
                  <p class="mb-1">
                    Caso você o banner seja cadastrado com a Largura normal, a
                    cor de fundo será aplicada além da largura do banner.
                  </p>
                  <strong
                    >O que acontecerá com o banner após o período de
                    exibição?</strong
                  >
                  <p>
                    Após o período de exibição, o banner será desativado
                    automaticamente e, consequentemente, deixará de ser exibido
                    no site.
                  </p>
                </div>
              </b-alert>
            </b-col>
          </div>

          <div class="form-row justify-content-end modal-footer-custom mt-0">
            <div class="col-md-6 col-12">
              <button
                type="button"
                @click="closeModalCreate()"
                class="btn btn-light float-left"
              >
                Fechar
              </button>
              <ButtonsActionsFooter
                variant="success"
                :submited="submited"
                :text="textSubmit"
                subtext="Aguarde..."
              />
            </div>
          </div>
        </form>
      </b-modal>

      <b-modal
        v-if="banner"
        id="showBanner"
        modal-class="modal-show"
        size="lg"
        :title="titleModal"
        centered
        hide-footer
        no-close-on-backdrop
      >
        <div class="form-row">
          <div class="col-md-12 col-12">
            <div class="form-row">
              <div class="col-md-6">
                <div class="form-group">
                  <strong>Título: </strong> {{ banner.title }}
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <strong>Título interno: </strong>
                  {{ banner.title_alternative }}
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <strong>Largura: </strong>
                  <span class="text-capitalize">{{ banner.width.title }}</span>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <strong>Exibir em: </strong> {{ banner.category.name }}
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <strong>Abertura: </strong> {{ banner.new_window.title }}
                </div>
              </div>

              <div class="col-md-3" v-if="banner.session">
                <div class="form-group">
                  <strong>Sessão: </strong> {{ banner.session.title }}
                </div>
              </div>

              <div v-if="banner.link" class="col-md-3">
                <div class="form-group">
                  <strong>Link: </strong>
                  <a
                    :href="banner.link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Abrir <i class="bi bi-box-arrow-up-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-md-3" v-if="banner.background">
                <div class="form-group">
                  <strong>Cor de fundo: </strong> {{ banner.background }}
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <strong>Exibir de: </strong> {{ banner.start }}
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <strong>Até: </strong> {{ banner.end }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="form-row">
          <div class="col-md-4">
            <b-img
              for="image"
              :src="banner.image_mobile"
              style="width: 100%; height: 200px; border-radius: 3px"
              fluid
            />
          </div>
          <div class="col-md-8">
            <b-img
              for="image"
              :src="banner.image_desktop"
              style="width: 100%; height: 200px; border-radius: 3px"
              fluid
            />
          </div>
        </div>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BSpinner,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BPagination,
  BMedia,
  BAvatar,
  BImg,
  BAlert,
  BTabs,
  BTab,
  BBadge,
  BModal,
  BFormGroup,
  BTooltip,
  BForm,
  BCardBody,
  BCardText,
  BFormRow,
  BContainer,
  BFormFile,
  BFormCheckbox,
  BListGroupItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { required, requiredIf } from "vuelidate/lib/validators";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

import CustomPaginateTable from "@/views/components/custom/PaginationTable";

import draggable from "vuedraggable";

import { mapState } from "vuex";

export default {
  setup() {
    return {
      avatarText,
    };
  },
  components: {
    draggable,
    BSpinner,
    BCard,
    BListGroupItem,
    BBadge,
    BCardBody,
    BAlert,
    CustomPaginateTable,
    BImg,
    BFormCheckbox,
    BFormFile,
    flatPickr,
    BModal,
    BAvatar,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    vSelect,
    BTooltip,
    BForm,
    BCardText,
    BFormRow,
    BContainer,
    ButtonsActionsFooter,
    BMedia,
    BTabs,
    BTab,
  },
  data() {
    return {
      updatingBanners: false,
      loadingItems: false,
      preview_mobile: "",
      preview_desktop: "",
      textSubmit: "",
      submited: false,
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 50,
      totalRows: 0,
      rowsTable: 0,
      optionsWindow: [
        { code: 0, title: "Mesma tela" },
        { code: 1, title: "Nova Janela" },
      ],
      dataItems: [],
      titleModal: "",
      optionsBannerCategories: [],
      titlePage: "Concursos",
      status: [
        { code: 1, title: "Ativo" },
        { code: 0, title: "Inativo" },
      ],
      item: {
        uuid: "",
        title: "",
        title_alternative: "",
        width: "",
        area: "",
        image: "",
        image_desktop: "",
        image_mobile: "",
        category: 7,
        background: "",
        link: "",
        new_window: "",
        start: null,
        end: null,
        contests: "",
        products: "",
        status: "",
        session: "",
      },
      banner: {
        title: "",
        area: "",
        title_alternative: "",
        width: "",
        image: "",
        image_desktop: "",
        image_mobile: "",
        category: "",
        background: "",
        link: "",
        new_window: "",
        start: "",
        end: "",
      },
      optionsProducts: [],
      optionsContests: [],
      optionsAreas: [],
      //optionsContest: [],
    };
  },
  validations: {
    preview_mobile: {
      required: requiredIf((vm) => {
        return vm.item.uuid ? false : true;
      }),
    },
    preview_desktop: {
      required: requiredIf((vm) => {
        return vm.item.uuid ? false : true;
      }),
    },
    item: {
      title: {
        required,
      },
      title_alternative: {
        required,
      },
      width: {
        required,
      },
      category: {
        required,
      },
      new_window: {
        required,
      },
      start: {
        required,
      },
      end: {
        required,
      },
      status: {
        required,
      },
      products: {
        required: requiredIf((vm) => {
          return vm.category === 9 ? true : false;
        }),
      },
      session: {
        required: requiredIf((vm) => {
          return vm.category === 11 ? true : false;
        }),
      },
      area: {
        required: requiredIf((vm) => {
          return vm.category === 13 ? true : false;
        }),
      },
      contests: {
        required: requiredIf((vm) => {
          return vm.category === 10 ? true : false;
        }),
      },
    },
  },
  // watch: {
  //   "item.category": {
  //     handler(selected) {
  //       if (selected === 9) {
  //         this.item.contests = "";
  //       } else if (selected === 10) {
  //         this.item.products = "";
  //       } else {
  //         this.item.contests = "";
  //         this.item.products = "";
  //       }
  //     },
  //   },
  // },
  mounted() {
    this.getData(this.item.category ?? 7);
    this.fetchBannerCategories();

    this.$root.$on("open:modal-create-banner", () => {
      this.textSubmit = "Cadastrar";
      this.titleModal = "Cadastrar Banners";
      this.$refs["modal-create-banner"].show();
      this.clearForm(this.item.category);
    });

    this.$root.$on("bv::modal::hidden", () => {
      this.clearForm();
    });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-banner");
  },
  computed: {
    ...mapState("Area", ["searchAreas"]),
  },
  methods: {
    showDraggable(category) {
      return category === 9 || category === 10;
    },
    isItemActive(status) {
      return status === 1 ? true : false;
    },
    isItemExpired(end) {
      return new Date(end) < new Date();
    },
    removeItem(element, index) {
      element.splice(index, 1);
    },
    closeModalCreate() {
      this.$refs["modal-create-banner"].hide();
    },
    async fetchAreas(term) {
      if (term.length > 2) {
        this.$store.dispatch("Area/search", term).then(() => {
          this.optionsAreas = this.searchAreas;
        });
      }
    },
    onTabChange(category) {
      this.item.category = category.code;
      this.titlePage = category.title;
      this.dataItems = [];
      this.loadingItems = true;
      this.getData(category.code);
    },
    onChangeSort(event) {
      if (event.moved) {
        this.updatingBanners = true;

        let items = this.dataItems;

        for (let item of items) {
          delete item.created_at;
          delete item.image;
          delete item.link;
          delete item.title;
          delete item.title_alternative;
        }

        this.$store
          .dispatch("Banner/sort", {
            category: this.item.category,
            banners: items,
          })
          .then(() => {
            this.updatingBanners = false;
            this.getData(this.item.category);
          });
      }
    },
    async fetchProducts(term) {
      this.optionsProducts = [];
      if (term.length > 2) {
        this.$store
          .dispatch("Product/search", { term: term, type: 0 })
          .then((res) => {
            this.optionsProducts = res;
          });
      }
    },
    fetchContests(term) {
      this.optionsContests = [];
      if (term.length > 2) {
        this.$store.dispatch("Contest/search", { term: term }).then((res) => {
          this.optionsContests = res;
        });
      }
    },
    previewImage(event, device) {
      const input = event.target;

      if (input.files) {
        const reader = new FileReader();

        if (input.files[0].size > 2262500) {
          this.notifyDefault("error", "Ops...imagem muito grande");
          return false;
        }
        reader.onload = (e) => {
          if (device == "desktop") {
            this.preview_desktop = e.target.result;
          } else {
            this.preview_mobile = e.target.result;
          }
        };
        const file = input.files[0];

        if (device == "desktop") {
          this.item.image_desktop = file;
        } else {
          this.item.image_mobile = file;
        }

        reader.readAsDataURL(file);
      }
    },
    async getData(categoryId) {
      this.submitedFilter = true;

      this.$store
        .dispatch("Banner/all", {
          currentPage: 1,
          perPage: 99999,
          categoryId: categoryId,
        })
        .then((data) => {
          console.log(data);
          if (data) {
            this.dataItems = data.data;
          }
        })
        .finally(() => {
          this.submitedFilter = false;
          this.loadingItems = false;
        });
    },
    modalDetails(uuid) {
      this.textSubmit = "Salvar";
      this.titleModal = "Alterar banner";

      this.$store.dispatch("Banner/show", uuid).then((res) => {
        this.banner = res;
      });

      this.item.uuid = uuid;
      this.$bvModal.show("showBanner");
    },
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      const formData = new FormData();

      formData.append("image_desktop", this.item.image_desktop);
      formData.append("image_mobile", this.item.image_mobile);
      formData.append("title", this.item.title);
      formData.append("title_alternative", this.item.title_alternative);
      formData.append("products", JSON.stringify(this.item.products) ?? "");
      formData.append("contests", JSON.stringify(this.item.contests) ?? "");
      // formData.append("contest", this.item.contest ? this.item.contest.code : '');
      formData.append("width", this.item.width ? this.item.width.code : "");
      formData.append("category", this.item.category);
      formData.append(
        "new_window",
        this.item.new_window ? this.item.new_window.code : ""
      );
      formData.append("link", this.item.link ?? "");
      formData.append("background", this.item.background ?? "");
      formData.append("area", this.item.area ? this.item.area.code : "");
      formData.append("start", this.item.start ?? "");
      formData.append("end", this.item.end ?? "");
      formData.append("status", this.item.status.code);

      formData.append(
        "session",
        this.item.session ? this.item.session.code : 0
      );

      if (!this.$v.$error) {
        if (this.item.uuid) {
          this.$store
            .dispatch("Banner/update", { uuid: this.item.uuid, data: formData })
            .then(() => {
              this.getData(this.item.category);
              this.fetchBannerCategories();
              this.notifyDefault("success");
              this.$v.$reset;
              this.$nextTick(() => {
                this.$refs["modal-create-banner"].hide();
                this.clearForm(this.item.category);
              });
            })
            .catch(() => {
              this.submited = false;
              this.notifyDefault("error");
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.$store
            .dispatch("Banner/create", formData)
            .then(() => {
              this.getData(this.item.category);
              this.fetchBannerCategories();
              this.notifyDefault("success");
              this.$v.$reset;
              this.$nextTick(() => {
                this.$refs["modal-create-banner"].hide();
                this.clearForm(this.item.category);
              });
            })
            .catch(() => {
              this.submited = false;
              this.notifyDefault("error");
            })
            .finally(() => {
              this.submited = false;
            });
        }
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData(this.item.category);
    },
    clearForm(categoryId) {
      this.preview_desktop = "";
      this.preview_mobile = "";
      this.item = {
        title: "",
        title_alternative: "",
        width: "",
        image: "",
        area: "",
        image_desktop: "",
        image_mobile: "",
        category: categoryId ?? 7,
        background: "",
        link: "",
        new_window: "",
        start: "",
        end: "",
        contests: "",
        products: "",
        uuid: "",
        session: "",
      };
    },
    async modalEdit(uuid) {
      this.textSubmit = "Salvar";
      this.titleModal = "Editar";
      this.clearForm();

      this.$store.dispatch("Banner/show", uuid).then((res) => {
        this.item = res;
        this.item.status = {
          code: this.item.status ? 1 : 0,
          title: this.item.status ? "Ativo" : "Inativo",
        };
        this.item.category = this.item.category.id;
        this.preview_desktop = this.item.image_desktop;
        this.preview_mobile = this.item.image_mobile;
        this.$refs["modal-create-banner"].show();
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updatePage() {
      this.getData(this.item.category);
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Banner/delete", uuid).then(() => {
            this.getData(this.item.category);
            this.fetchBannerCategories();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
    async fetchBannerCategories() {
      this.$store.dispatch("Banner/categories").then((res) => {
        this.optionsBannerCategories = res;
      });
    },
  },
  directives: {
    Ripple,
  },
};
</script>
<style lang="scss">
.list-group-item {
  transition: all 1s;
}
.selected-items {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.chip {
  display: inline-flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 16px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.chip-close {
  background: none;
  border: none;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}

.chip-close:hover {
  color: red;
}
</style>
